<template>
  <div>
    <p class="text-center text-14 text-bold p-b-5">产品分析</p>

    <Row :gutter="16" class="analysis-border-bottom">
      <i-col span="12" class="analysis-border-right">
        <p class="text-14 text-bold">售卖对比</p>
        <Row>
          <i-col span="12">
            <RadioGroup v-model="chooseFeature" size="small" type="button" button-style="solid">
                <Radio label="1">渠道</Radio>
                <Radio label="2">线路</Radio>
            </RadioGroup>
          </i-col>
          <i-col span="12">
            <Select size="small" v-model="chooseProductId" @on-change="loadProductSaleComparision">
              <Option v-for="item in assetProducts" :value="item.id" :key="'1_'+item.id">{{ item.content }}</Option>
            </Select>
          </i-col>
        </Row>
        <div style="width:100%;height:160px" id="analysisSaleChartReport"></div>
      </i-col>
      <i-col span="12">
        <p class="text-14 text-bold">售卖占比</p>
        <Row  class="table-title text-right">
          <i-col span="4" class="text-left">产品</i-col>
          <i-col span="4">总库存</i-col>
          <i-col span="4">已售</i-col>
          <i-col span="4">去化率</i-col>
          <i-col span="4">签约额(万元)</i-col>
          <i-col span="4">金额涨幅</i-col>
        </Row>
        <Row v-for="(item,index) in productSalePercentageData" :key="'2_' + index" class="p-t-5 text-right">
          <i-col span="4" class="text-left">{{item.name}}</i-col>
          <i-col span="4">{{formatNumber(item.totalNumber)}}</i-col>
          <i-col span="4">{{formatNumber(item.saleNumber)}}</i-col>
          <i-col span="4">{{item.rate}} %</i-col>
          <i-col span="4">{{formatMoneyByThousand(item.actualAmount)}}</i-col>
          <i-col span="4">
            <span :class="getChainRateColor(item.chainRate)">{{item.chainRate}}%</span>
          </i-col>
        </Row>
        <div v-show="showArray.length>currentPageSize" class="paging_style">
          <Page size="small" :total="showArray.length" :page-size="currentPageSize" :current="currentPageNumber" @on-change="handlePageChange"></Page>
        </div>
        <p v-if="productSalePercentageData.length===0" class="remark p-t-20 text-center">暂无数据</p>
      </i-col>
    </Row>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { toNumber } from '@/utils/wnumb_own'
import { getStorage } from '@/utils/storage'

import { getProductSaleComparision, getProductSalePercentage } from '@/api/dw/manage'
import { listAssetProducts } from '@/api/dw/asset'

export default {
  data () {
    return {
      publisherId: this.$store.getters.token.userInfo.publisherId,

      chooseFeature: '1', // 查询类型
      assetProducts: [], // 线路产品集合
      chooseProductId: null,

      productSalePercentageData: [],
      showArray: [],
      currentPageSize: 5,
      currentPageNumber: 1,

      dataSourcePie: null
    }
  },
  mounted () {
    this.initPageData()
    this.loadProductSalePercentage()
  },
  methods: {
    initPageData () {
      const queryModel = {
        publisherId: this.publisherId
      }
      listAssetProducts(queryModel).then(res => {
        this.assetProducts = res
        this.chooseProductId = res[0].id
        this.loadProductSaleComparision()
      })
    },
    loadProductSaleComparision () { // 载入报表数据
      const queryModel = {
        startDate: this.searchSchedule.startDate,
        endDate: this.searchSchedule.endDate,
        publisherId: this.publisherId,
        queryType: this.chooseFeature,
        productId: this.chooseProductId
      }
      getProductSaleComparision(queryModel).then(res => {
        const legends = []
        const xLabels = []
        const ySeries = []

        res.forEach(element => {
          if (!legends.includes(element.s)) {
            legends.push(element.s)
          }

          if (!xLabels.includes(element.x)) {
            xLabels.push(element.x)
          }
        })

        legends.forEach(element => {
          ySeries.push({
            name: element,
            type: 'line',
            // step: 'start',
            animationDuration: 2000,
            data: res.filter(x => x.s === element).map(x => (x.y * 0.0001).toFixed(2))
          })
        })

        this.initReportChart(legends, xLabels, ySeries)
      })
    },
    initReportChart (legends, xLabels, ySeries) {
      if (this.dataSourcePie != null && this.dataSourcePie !== '' && this.dataSourcePie !== undefined) {
        this.dataSourcePie.dispose()
      }

      this.$nextTick(() => {
        // 防止页面被切换，元素被移除
        if (document.getElementById('analysisSaleChartReport')) {
          this.dataSourcePie = echarts.init(document.getElementById('analysisSaleChartReport'))
          const option = {
            tooltip: {
              trigger: 'axis'
            },
            legend: {
              data: legends,
              right: 0,
              textStyle: {
                color: this.reportFontColor
              }
            },
            grid: {
              left: 0,
              right: 0,
              bottom: 0,
              top: 45,
              containLabel: true
            },
            xAxis: {
              type: 'category',
              data: xLabels,
              axisLabel: {
                color: '#c5c8ce'
              }
            },
            yAxis: {
              type: 'value',
              splitLine: {
                show: false
              },
              axisLine: {
                show: true
              }
            },
            series: ySeries
          }

          this.dataSourcePie.setOption(option, true)
          const that = this
          window.addEventListener('resize', function () {
            that.dataSourcePie.resize()
          })
        }
      })
    },
    loadProductSalePercentage () { // 加载售卖对比
      const queryModel = {
        compareEndDate: this.compareSchedule.endDate,
        compareStartDate: this.compareSchedule.startDate,
        startDate: this.searchSchedule.startDate,
        endDate: this.searchSchedule.endDate,
        publisherId: this.publisherId
      }
      getProductSalePercentage(queryModel).then(res => {
        this.showArray = res
        this.handlePageChange(1)
      })
    },
    handlePageChange (page) {
      this.currentPageNumber = page
      const newArray = []
      for (let index = this.currentPageSize * (page - 1); index < this.currentPageSize * page; index++) {
        if (index < this.showArray.length) {
          newArray.push(this.showArray[index])
        } else {
          break
        }
      }

      this.productSalePercentageData = newArray
    },
    formatNumber (number) {
      return toNumber(number)
    },
    formatMoneyByThousand (number) {
      return (number * 0.0001).toFixed(2)
    },
    getChainRateColor (number) {
      let color = 'text-red'
      if (number < 0) {
        color = 'text-green'
      } else if (number === 0) {
        color = 'text-yellow'
      }
      return color
    }
  },
  computed: {
    searchSchedule () {
      return this.$store.state.situationWeb.searchSchedule
    },
    compareSchedule () {
      return this.$store.state.situationWeb.compareSchedule
    },
    reportFontColor () {
      // 获取并设置当前页面的主题
      const theme = getStorage('oohforce-workbench-theme') || 'dark'
      return theme === 'dark' ? '#fff' : '#000'
    }
  },
  watch: {
    searchSchedule: {
      deep: true,
      handler (newValue) {
        this.loadProductSalePercentage()
        this.loadProductSaleComparision()
      }
    },
    compareSchedule: {
      deep: true,
      handler (newValue) {
        this.loadProductSalePercentage()
      }
    },
    chooseFeature () {
      this.loadProductSaleComparision()
    }
  }
}
</script>

<style>

</style>
